












































































import { Component, Vue } from 'vue-property-decorator'
import { apigetWriteOffInfo, apiPlatformWriteOrder } from '@/api/order/order'

@Component
export default class WriteOffOrderDetail extends Vue {
    $scopedSlots!: {
        default: undefined;
        [key: string]: undefined | ((props: any) => any);
    };

    detail: any = {
        order_sn: '202411291645442609',
        code: 'WX202411290001',
        state: 1,
        price: 100,
        discount_rate: '0.9',
        settlement_amount: 90,
        create_time: '2024-11-29 16:45:45',
        store_name: '重庆本地门店1',
        staff_name: '张三',
        refund_amount: 90,
        refund_time: '2024-11-29 17:00:00',
        refund_reason: '客户申请退款'
    }
    list: any = []

    async getDetail() {
        try {
            const id = this.$route.query.id
            if (!id) {
                this.$message.error('参数错误')
                this.$router.back()
                return
            }
            const response = await apigetWriteOffInfo({ id: id as string })
            this.detail = response
            this.list.push(response)
        } catch (error: any) {
            this.$message.error(error?.message || '获取详情失败')
            this.$router.back()
        }
    }

    created() {
        this.getDetail()
    }

    getStatusType(state: number): string {
        const typeMap: { [key: number]: string } = {
            1: 'success',
            2: 'danger',
            3: 'info'
        }
        return typeMap[state] || ''
    }

    getStatusText(state: number): string {
        const textMap: { [key: number]: string } = {
            1: '核销成功',
            2: '核销失败',
            3: '已退款'
        }
        return textMap[state] || '未知状态'
    }

    getStatusIcon(state: number): string {
        const iconMap: { [key: number]: string } = {
            1: 'el-icon-check',
            2: 'el-icon-close',
            3: 'el-icon-refresh'
        }
        return iconMap[state] || ''
    }
}
